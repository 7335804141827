import React, { FC } from "react";
import useLabelRangeSlider from "./useLabelRangeSlider";
import classes from "./LabelRangeSlider.module.css";
import Slider from "rc-slider";
import { LabelRangeSliderProps } from "../../types";

const LabelRangeSlider: FC<LabelRangeSliderProps> = ({
  mark,
  keyName,
  handleFilterType,
  removeFilterKey,
  filter,
  setChangeMinMaxValue,
  rangeValue,
  setBackToSelectDiamondPage,
}) => {
  const {
    marks,
    value,
    handleChange,
    handleLabelChangeComplete,
    setIsFocus,
    handleContextMenu,
  } = useLabelRangeSlider({
    mark,
    keyName,
    handleFilterType,
    filter,
    removeFilterKey,
    setChangeMinMaxValue,
    rangeValue,
    setBackToSelectDiamondPage,
  });
  return (
    <div
      className={classes.root}
      onContextMenu={handleContextMenu}
      style={{ userSelect: "none", position: "relative" }}
    >
      <Slider
        onChangeComplete={(val)=>{
          handleLabelChangeComplete(val)
          setIsFocus(false)
        }}
        onBeforeChange={()=>setIsFocus(true)}
        dots
        range
        min={+Object.keys(marks)[0]}
        max={+Object.keys(marks)[Object.keys(marks).length - 1]}
        marks={marks}
        step={1}
        defaultValue={[
          +Object.keys(marks)[0],
          +Object.keys(marks)[Object.keys(marks).length - 1],
        ]}
        value={value}
        onChange={handleChange}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        allowCross={false}
        pushable
        draggableTrack={false}
        handleStyle={{ borderColor: "#4a4a4a", backgroundColor: "#fff" }}
        trackStyle={{ backgroundColor: "#4a4a4a" }}
        dotStyle={{ border: "none" }}
      />
    </div>
  );
};

export default LabelRangeSlider;
